import { DialogContent, DialogContentText, TextField } from "@mui/material";
import React from "react";
import { BaseConfirmDialog } from "./BaseConfirmDialog";
import { ConfirmDialogProps, FormConfirmDialogProps } from "./types";

export const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  subtitle,
  subtitle2,
  ...props
}) => {
  return (
    <BaseConfirmDialog {...props}>
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          variant="body2"
          color="text.secondary"
        >
          {subtitle}
        </DialogContentText>
        {!!subtitle2 && (
          <DialogContentText
            id="dialog-description-2"
            variant="body2"
            color="text.secondary"
          >
            {subtitle2}
          </DialogContentText>
        )}
      </DialogContent>
    </BaseConfirmDialog>
  );
};

export const FormConfirmDialog: React.FC<FormConfirmDialogProps> = ({
  inputFieldId,
  subtitle,
  inputFieldLabel,
  inputFieldPlaceholder,
  ...props
}) => {
  const [inputValue, setInputValue] = React.useState("");
  return (
    <BaseConfirmDialog
      {...props}
      onClickConfirm={(
        event?: React.MouseEvent<HTMLButtonElement, MouseEvent>
      ) => {
        event?.preventDefault();
        inputValue && props.onSubmitText?.(inputValue);
        props.onClickConfirm?.(event);
      }}
      confirmButtonProps={{ ...props.confirmButtonProps, type: "submit" }}
    >
      <DialogContent>
        <DialogContentText
          id="dialog-description"
          sx={{ fontWeight: "light", typography: "body2" }}
        >
          {subtitle}
        </DialogContentText>
        <TextField
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          id={inputFieldId}
          label={inputFieldLabel}
          placeholder={inputFieldPlaceholder}
          fullWidth
          autoFocus
          variant="outlined"
          margin="normal"
          autoComplete="off"
        />
      </DialogContent>
    </BaseConfirmDialog>
  );
};
