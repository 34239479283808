export interface UserSignupAPI {
  id: number;
  date_joined: string;
}

export interface UserSignup {
  id: number;
  dateJoinedUTCString: string;
}
export interface SignupCountOnDate {
  signupDateString: string;
  count: number;
}

export interface SubscriptionStatusBreakdownAPI {
  billing_period_breakdown: {
    [key: string]: number;
  };
  subscription_status: string;
  count: number;
}
export interface SubscriptionStatusBreakdown {
  subscriptionStatus: SubscriptionStatus;
  count: number;
  billingPeriodBreakdown: {
    [key: string]: number;
  };
}

export enum SubscriptionStatus {
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  TRIALING = "trialing",
  ACTIVE = "active",
  PAST_DUE = "past_due",
  CANCELED = "canceled",
  UNPAID = "unpaid",
}

export const DISPLAYED_SUBSCRIPTION_STATUSES: SubscriptionStatus[] = [
  SubscriptionStatus.ACTIVE,
  SubscriptionStatus.TRIALING,
];
