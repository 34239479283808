import { Card, Stack, Typography } from "@mui/material";
import { ComputedDatum } from "@nivo/pie";
import { capitalCase } from "change-case";
import React from "react";

import { PieChartDatum } from "../../../common/Charts/types";
import { SubscriptionStatus } from "../types";
import { SubscriptionStatusTooltipData } from "../utils";

interface TooltipProps {
  datum: ComputedDatum<
    PieChartDatum<SubscriptionStatus, SubscriptionStatusTooltipData>
  >;
}

export const SubscriptionBreakdownTooltip: React.FC<TooltipProps> = ({
  datum,
}) => {
  const { data: { tooltipData: { billingPeriodBreakdown = {} } = {} } = {} } =
    datum;
  return (
    <Card elevation={3} sx={{ p: 1, borderRadius: 1 }}>
      <Typography variant="body2" fontWeight={800}>
        {datum.label}
      </Typography>
      {Object.keys(billingPeriodBreakdown).map((key) => {
        const billingPeriodFansCount = billingPeriodBreakdown[key];
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            key={key}
            gap={1}
          >
            <Typography variant="body2" component={"div"} fontSize={14}>
              {capitalCase(key)}:
            </Typography>
            <Typography
              variant="body2"
              component={"div"}
              key={key}
              fontSize={14}
            >
              {billingPeriodFansCount}{" "}
            </Typography>
          </Stack>
        );
      })}
    </Card>
  );
};
